import type { IGatsbyImageData } from 'gatsby-plugin-image';

export interface GatsbyImage {
  gatsbyImageData: IGatsbyImageData;
  title: string;
}

export function isGatsbyImage(image: any): image is GatsbyImage {
  return !!(image && typeof image === 'object' && image.gatsbyImageData);
}
