export interface ContentfulSVGImage {
  file: {
    url: string;
    fileName: string;
    contentType: 'image/svg+xml';
  };
  title: string;
}

export function isContentfulSVGImage(image: ContentfulSVGImage | any): image is ContentfulSVGImage {
  if (!image || typeof image !== 'object') return false;
  if (!image?.file?.url || typeof image.file.url !== 'string') return false;
  if (image.file.contentType !== 'image/svg+xml') return false;

  return true;
}
