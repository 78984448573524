import ColumnGraphsPieChart from 'images/shared-page-assets/column-graphs-pie-chart.inline.svg';
import PieChartHalvedSpheres from 'images/shared-page-assets/pie-chart-halved-spheres.inline.svg';
import { useGatsbyImage } from 'hooks/useGatsbyImage';
import type { ImageCardProps } from 'components/ImageCard';

export const usePartnerCards = () => [
  {
    cardIdentifier: 'track-1',
    title: 'Differentiate and retain',
    text: 'Upgrade your customer experience offering by providing clients with an award-winning portfolio tracker, for improved ‘stickiness’.',
    image: useGatsbyImage({
      name: 'become-a-partner/hand-with-person',
      alt: 'Illustration of a hand holding a person icon',
    }),
  },
  {
    cardIdentifier: 'track-2',
    title: 'Meet client demand',
    text: 'Build to the Sharesight API and allow your customers to easily import their historical trades into their Sharesight portfolio.',
    image: useGatsbyImage({
      name: 'become-a-partner/report',
      alt: 'Graphic illustration of a report',
    }),
  },
  {
    cardIdentifier: 'track-3',
    title: 'Convert and grow',
    text: 'Gain exposure to our fast-growing universe of self-directed investors and get the support of Sharesight partner marketing.',
    image: useGatsbyImage({
      name: 'become-a-partner/hands-with-money',
      alt: 'Illustration of two hands with a currency symbol between',
    }),
  },
  {
    cardIdentifier: 'track-4',
    title: 'Leverage support',
    text: 'Get the endorsement and support you need from the Sharesight team, with dedicated account management and product training.',
    image: useGatsbyImage({ name: 'become-a-partner/chat', alt: 'Illustration of a speech box' }),
  },
  {
    cardIdentifier: 'track-5',
    title: 'Get data insights',
    text: 'Gain actionable insights from credible 3rd party providers, with secure financial and trading data input and retrieval.',
    image: useGatsbyImage({ name: 'become-a-partner/bulb', alt: 'Illustration of a lightbulb' }),
  },
  {
    cardIdentifier: 'track-6',
    title: 'Extended functionality',
    text: 'A superior out-of-the-box portfolio reporting solution that’s fast and easy to deploy, so you can stay focused on what you do best.',
    image: useGatsbyImage({ name: 'become-a-partner/gears', alt: 'Illustration of three cogs' }),
  },
];

export const partnerTypeCards: ImageCardProps[] = [
  {
    title: 'Accountants & financial advisers',
    content: (
      <p>Simplified portfolio admin and intelligence for your clients, with partner benefits.</p>
    ),
    localize: true,
    showButton: true,
    buttonText: 'View partner program',
    to: '/professionals',
    image: <ColumnGraphsPieChart />,
    background: 'orangeHighlight',
  },
  {
    title: 'API technology partners',
    content: (
      <p>
        Connect to the Sharesight API for portfolio data input and retrieval, with partner benefits.
      </p>
    ),
    localize: true,
    showButton: true,
    buttonText: 'View partner program',
    to: '/api-technology-partners',
    image: <PieChartHalvedSpheres />,
    background: 'yellowHighlight',
  },
];
